<template>
	<div class="page-container page_regi">
		<!-- <RegiHead /> -->
		<div class="regi-bd change_bd">
			<div class="regi_name">{{$t('acTxt8')}}</div>
			<el-form ref="form" class="regi-from" :rules="rules" :model="form">
				<el-form-item prop="password">
					<div class="regi_group">
						<div class="regi_gr_t">{{$t('acTxt6')}}</div>
						<div class="regi_gr_b">
							<el-input v-model="form.password" placeholder="" type="password">
							</el-input>
						</div>
					</div>
				</el-form-item>
				<el-form-item prop="checkpassword">
					<div class="regi_group">
						<div class="regi_gr_t">{{$t('password4')}}</div>
						<div class="regi_gr_b">
							<el-input v-model="form.checkpassword" placeholder="" type="password">
							</el-input>
						</div>
					</div>
				</el-form-item>
				<el-form-item>
					<div class="regi_group chan_btn flex flex-ac flex-between">
						<el-button class="btn btn_reset" size="large"  type="primary" @click="resetForm('form')">{{$t('reset')}}
						</el-button>
						<el-button class="btn" size="large"  type="primary" @click="submitForm('form')">{{$t('save')}}</el-button>
					</div>
				</el-form-item>
			</el-form>
			<!-- <div class="regi_tips">Copyright ©2024 Alcex All rights reserved.</div> -->
		</div>
	</div>
</template>

<script>
	import {
		transactionPass
	} from '@/api/api/user'
	import RegiHead from '@/components/RegiHead.vue'
	export default {
		components: {
			RegiHead,
		},
		data() {
			var validatePass = (rule, value, callback) => {
				if (value === "") {
					callback(new Error(this.$t("chtip4")));
				} else if (!/([a-zA-Z0-9]){6,18}/.test(value)) {
					callback(new Error(this.$t("chtip4")));
				} else {
					callback();
				}
			};
			var validatePass2 = (rule, value, callback) => {
				if (value === "") {
					callback(new Error(this.$t("chtip4")));
				} else if (!/([a-zA-Z0-9]){6,18}/.test(value)) {
					callback(new Error(this.$t("chtip5")));
				} else if (value !== this.form.password) {
					callback(new Error(this.$t("chtip5")));
				} else {
					callback();
				}
			};
			return {
				form: {
					password: '',
					checkpassword: ''
				},
				rules: {
					password: [{
							required: true,
							type: "string",
							min: 6,
							message: this.$t("chtip4"),
							trigger: "blur"
						},
						{
							validator: validatePass,
							trigger: 'blur'
						}
					],
					checkpassword: [{
						required: true,
						type: "string",
						min: 6,
						message: this.$t("chtip5"),
						trigger: "blur"
					}, {
						validator: validatePass2,
						trigger: 'blur'
					}]
				},
			};
		},
		methods: {
			submitForm(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						transactionPass({
							jyPassword: this.form.password
						}).then(res => {
							if (res.code == 0) {
								this.$router.push('/admin')
							} else {
								this.$message.error(res.message);
							}
						})
					} else {
						console.log('error submit!!');
						return false;
					}
				});
			},
			resetForm(formName) {
				this.$refs[formName].resetFields();
			}
		}
	}
</script>
